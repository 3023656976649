import translations from '../../../utils/translations'
import { CRM_TAGS, crmTypesTags, CUSTOM_FIELD } from '../../../constants/crm-types-tags'
import _ from 'lodash'
import { createSuffixedName } from '../../../utils/utils'
import { isCrmTypeAllowedOnce } from '../../../panels/contact-sync-panel/utils'
import { FIELD_COMPONENT_TYPES, FormsFieldPreset } from '@wix/forms-common'
import { fieldsStore } from '../preset/fields/fields-store'
import { PremiumRestriction } from '../../../constants/premium'
import { AUTOFILL_MEMBER_EMAIL_ROLE } from '../../../constants/roles'
import { getComponentByRole } from '../services/form-service'
import {
  getPrimaryConnectionFromStructure,
  isComplexAddressInnerField,
  isInputField,
} from '../utils'
import { MAXIMUM_FIELD_NAME_LENGTH } from '../../../constants/field-validation'

export const getDuplicatedFieldsConfig = (
  fields: Partial<FormField>[],
  configs: ComponentConfig[],
): ComponentConfig[] => {
  const fieldsCrmLabels = _.map(fields, (field: Partial<FormField>) => field.crmLabel)

  const newCrmLabels = _.reduce(
    configs,
    (acc, config) => {
      const newCrmLabel = createSuffixedName(
        fieldsCrmLabels,
        translations.t('manageFields.duplicatedFormName', {
          name: config.crmLabel,
        }),
      )

      fieldsCrmLabels.push(newCrmLabel)

      return [...acc, newCrmLabel]
    },
    [],
  )

  return _.map(_.zip(configs, newCrmLabels), ([config, newCrmLabel]) => {
    const isCrmTypeTag = !!crmTypesTags[config.crmType]

    const fieldData = fieldsStore.get(config.fieldType)
    const fieldType = fieldData.metadata.allowOnlyOnce
      ? fieldData.base.metadata.fieldType
      : fieldData.metadata.fieldType

    return _.assign({}, config, {
      collectionFieldType: fieldsStore.get(config.fieldType).properties.collectionFieldType,
      crmLabel: newCrmLabel,
      crmTag: isCrmTypeTag ? CRM_TAGS.OTHER : config.crmTag,
      crmType: isCrmTypeTag ? config.crmType : CUSTOM_FIELD,
      fieldType,
      customFieldKey: undefined,
      customFieldName: undefined,
      collectionFieldKey: createSuffixedName(
        _.map(fields, 'collectionFieldKey'),
        _.camelCase(newCrmLabel),
        '',
      ),
    })
  })
}

export const getDefaultLabel = ({
  titleText,
  buttonLabel,
  label,
  placeholder,
  fieldType,
  labelFromConnection,
}: {
  titleText: string
  buttonLabel: string
  label: string
  placeholder: any
  fieldType: FieldPreset
  labelFromConnection?: string
}) => {
  const componentType = fieldsStore.get(fieldType).properties.componentType
  const fieldPlaceholder = _.isString(placeholder) ? placeholder : _.get(placeholder, 'text')

  switch (componentType) {
    case FIELD_COMPONENT_TYPES.FILE_UPLOADER:
      return label || labelFromConnection || buttonLabel
    case FIELD_COMPONENT_TYPES.SIGNATURE_INPUT:
    case FIELD_COMPONENT_TYPES.RATING:
      return titleText || labelFromConnection
    case FIELD_COMPONENT_TYPES.CHECKBOX_GROUP:
      return label || labelFromConnection
    default:
      return label || labelFromConnection || fieldPlaceholder
  }
}

const _getDefaultFieldName = ({
  fieldStructure,
  fieldsOnStage,
}: {
  fieldStructure: any
  fieldsOnStage?: FormField[]
}): string => {
  const data = fieldStructure.data.data
  const { placeholder, titleText, buttonLabel, label, placeholderLabel } = data

  const { crmLabel, fieldType } = fieldStructure.connectionConfig
  const fieldNames = getAllCrmLabels(fieldsOnStage)

  const defaultPlaceholder: string = placeholder || placeholderLabel

  const defaultLabel: string = getDefaultLabel({
    titleText,
    buttonLabel,
    label,
    placeholder: defaultPlaceholder,
    fieldType,
    labelFromConnection: '',
  })
  const defaultFieldName = (defaultLabel || crmLabel).slice(0, MAXIMUM_FIELD_NAME_LENGTH)

  return createSuffixedName(fieldNames, defaultFieldName, ' ')
}

export const getDefaultFieldName = ({
  fieldStructure,
  fieldsOnStage,
}: {
  fieldStructure: any
  fieldsOnStage?: FormField[]
}): string => {
  const mainRole = getFieldMainRole(fieldStructure.connectionConfig.fieldType)

  if (mainRole) {
    const innerField = getComponentByRole(fieldStructure.data.components[0], mainRole)

    return _getDefaultFieldName({
      fieldStructure: {
        data: {
          data: innerField.data,
        },
        connectionConfig: getConfigFromStructure(innerField),
      },
      fieldsOnStage,
    })
  } else {
    return _getDefaultFieldName({ fieldStructure, fieldsOnStage })
  }
}
export const getFieldByRole = (fields: FormField[], role) => fields.find((f) => f.role === role)

const removeFieldFromListById = (fields: FormField[], id: string) =>
  fields.filter((f) => f.componentRef.id !== id)

export const removeFieldFromList = (fields: FormField[], field: FormField) =>
  removeFieldFromListById(fields, field.componentRef.id)

export const getFieldMainRole = (fieldType: FormsFieldPreset) =>
  fieldType && fieldsStore.get(fieldType).metadata.mainRole

export const isFieldAllowedOnceByCrmType = (crmType) => crmType && isCrmTypeAllowedOnce(crmType)

export const isFieldAllowedOnceByFieldType = (fieldType) =>
  fieldType && !!fieldsStore.get(fieldType).metadata.allowOnlyOnce

export const isFieldAllowedOnlyOnce = (fieldType, crmType) =>
  isFieldAllowedOnceByFieldType(fieldType) || isFieldAllowedOnceByCrmType(crmType)


export const isCustomFieldAndOnStage = (field: { customFieldKey: string }, fieldsOnStage) =>
  field.customFieldKey && _.includes(_.map(fieldsOnStage, 'customFieldKey'), field.customFieldKey)

export const isAllowedOnceAndOnStage = (fieldType: FieldPreset, fieldsOnStage: FormField[]) => {
  const crmType = fieldsStore.get(fieldType).crmType
  return (
    (isFieldAllowedOnceByFieldType(fieldType) &&
      _.includes(_.map(fieldsOnStage, 'fieldType'), fieldType)) ||
    (isFieldAllowedOnceByCrmType(crmType) && _.includes(_.map(fieldsOnStage, 'crmType'), crmType))
  )
}

export const getCountableFields = (fields: FormField[]) =>
  _.filter(fields, (field) => {
    const fieldType = _.get(field, 'fieldType')
    const fieldData = fieldsStore.get(fieldType)
    return fieldData && fieldData.countable
  })

export const getFieldsLeft = (fields: FormField[], restrictions: PremiumRestriction) => {
  const { limit } = restrictions.fields
  const countableFieldsLength = getCountableFields(fields).length
  return limit > -1 ? limit - countableFieldsLength : Infinity
}

export const getAutofillMemberEmailConnection = (
  connections: Partial<ComponentConnection>[],
): Partial<ComponentConnection> =>
  connections.find(
    (connection: ComponentConnection) => connection.role === AUTOFILL_MEMBER_EMAIL_ROLE,
  )

export const getConfigFromStructure = (structure: ComponentStructure) =>
  JSON.parse(getPrimaryConnectionFromStructure(structure).config)

export const getAllCrmLabels = (fields: FormField[]): string[] => {
  const fieldsWithInnerFields = _.flatten(_.map(fields, (field) => [field, ...field.childFields]))

  return _.map(
    _.filter(
      fieldsWithInnerFields,
      ({ role }) => isInputField(role) || isComplexAddressInnerField(role),
    ),
    (field: FormField) => field.crmLabel,
  )
}

export const createFieldsDataForCollectionActions = (
  fieldsAndUpdatedFieldsConfigs: {
    field: FormField
    updatedFieldConfig: ComponentConfig
  }[],
) =>
  _.map(fieldsAndUpdatedFieldsConfigs, ({ field, updatedFieldConfig }) => ({
    role: field.role,
    fieldType: field.fieldType,
    componentRef: field.componentRef,
    crmLabel: updatedFieldConfig.crmLabel,
    collectionFieldKey: updatedFieldConfig.collectionFieldKey,
    collectionFieldType: updatedFieldConfig.collectionFieldType,
  }))

export const convertFieldOptionsToServerOptions = (field: FormField): ServerFieldOption[] => {
  return field.options.map((opt) => ({
    value: opt.value,
    label: _.get(opt, 'label') || _.get(opt, 'text'),
  }))
}

export const convertPaymentFieldOptionsToServerOptions = (field: FormField): ServerFieldOption[] =>
  field.options.map(({ label }: RadioOption) => ({
    value: label,
    label,
  }))
